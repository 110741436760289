


































import { Component, Prop, Vue } from "vue-property-decorator";
import CFMGridPage from "@/scripts/CFMGridPage";
import { CFMModelWorkOrderSparePartView } from "@/scripts/services/ClientApiAuto";
import { mixinz } from "@/scripts/global/mixinz";
import * as blockui from '@/helpers/block-ui'
@Component<SparePartGrid>({
  mixins: [mixinz],
})
export default class SparePartGrid extends CFMGridPage<CFMModelWorkOrderSparePartView> {
  @Prop({ required: true }) workOrderId!: string;
  entityType = "WorkOrderSparePart";
  sorts: { field: string; direction: string }[] = [
    { field: "WorkOrderId", direction: "Descending" },
  ];
  gridSparePartProps: any = {
      ref: 'grid',
      allowPaging: true,
      allowFiltering: true,
      allowSorting: true,
      allowGrouping: false,
      allowResizing: true,
      allowExcelExport: false,
      allowPdfExport: false,
      allowCsvExport: false,
    }
  // @ts-ignore
  filterSettings = {
    type: "Menu",
    // @ts-ignore
    columns: [
      // @ts-ignore
      {
        field: "WorkOrderId",
        matchCase: false,
        operator: "equal",
        predicate: "and",
        value: parseInt(this.workOrderId),
      },
    ],
  };
  async created() {
    this.filterSettings = {
      type: "Menu",
      // @ts-ignore
      columns: [
        //@ts-ignore
        {
          field: "WorkOrderId",
          matchCase: false,
          operator: "equal",
          predicate: "and",
          value: parseInt(this.workOrderId),
        },
      ],
    };
  }
  rowDataBound(args: any) {
    if (!args || !args.data) return;

    switch (Number(args.data["ReserveStatus"])) {
      case 1:
        args.row.classList.add("bg-white");
        break;
      case 2:
        args.row.classList.add("bg-yellow");
        break;
      case 3:
        args.row.classList.add("bg-green");
        break;
      default:
        break;
    }
  }
  async mounted() {
    this.dataStateChange(this.state);
    this.$eventHub.$on('sparePartAdded', this.refreshGrid);
  }

  refreshGrid() {
    this.dataStateChange(this.state);
  }

  reserveDeleteTemplate() {
      const self = this;
      const methods = {
          async reserveDeleted(dataList: any) {
              const formData = {
                  SparePartId: dataList.SparePartId,
                  WorkOrderId: Number(self.workOrderId),
                  WarehouseId: dataList.WarehouseId,
                  SerialNumber: dataList.SerialNumber,
                  ReservedCount: dataList.ReservedCount,
                  ReturnedCount: dataList.ReturnedCount || 0,
              };

              try {
                  blockui.pageBlock(self);
                  const response = await self.apiClient.workOrderSparePart.deleteReservedSparePart(formData);
                  if (response.data.IsSuccess) {
                      toastr.success(self.translate('delete_successfully'));
                      self.refreshGrid();
                      self.$eventHub.$emit('reloadTabCounts', { workOrderId: self.workOrderId });
                  } else {
                      const errorMessage = response.data.Message || "Error";
                      toastr.error(errorMessage);
                  }
              } catch (error) {
                  console.error("Error sending form data to API:", error);
              } finally {
                  blockui.unBlockPage();
              }
          },
      };
      return {
          template: Vue.component("columnTemplate", {
              template: `<div v-if="data.ReserveStatus === 1"><button @click="reserveDeleted(data)" class="btn btn-danger" v-lang.delete>Delete</button></div>`,
              methods: methods,
          }),
      };
  }
}
