import Vue from 'vue';
import { mapState } from 'vuex';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import * as swal from '../../helpers/swal';
import * as api from '../services/api';
import * as dateFormat from '../../helpers/dateFormat';
import * as blockui from '../../helpers/block-ui';
import scheduler from '../global/scheduler';

import { authIncidentSave, authWorkOrderSave } from '../services/authorization';

const moment = extendMoment(Moment);

export default {
  data() {
    const self = this;
    return {
      dateHelper: bryntum.scheduler.DateHelper,
      widgetHelper: bryntum.scheduler.WidgetHelper,
      workSchEngine: undefined,
      userSchEngine: undefined,
      IsWorkReady: false,
      IsUserReady: false,
      selectedWorkOrder: undefined,
      shiftContainer: undefined,
      workContainer: undefined,
      userEventTooltip: {
        header: {
          titleAlign: 'start',
        },
        tools: [
          {
            cls: 'b-fa b-fa-angle-double-right',
            handler() {
              if (self.maintenanceGroupId || (this.eventRecord.data.woId && self.workOrderId !== this.eventRecord.data.woId)) {
                this.hide();
                self.$router.replace({ query: { workOrderId: this.eventRecord.data.woId } });
                self.maintenanceGroupId = undefined;
                // self.workOrderId = this.eventRecord.data.woId;
                self.selectedWorkOrder = undefined;
                self.getWorkEvents();
              }
            },
          },
        ],
        template: (data) => `
                <dl>
                    <dt>${this.translate('staff')} : ${data.eventRecord.resource.staff}</dt>
                    ${data.eventRecord.data.woId ? `<dt>${this.translate('work_order')} : # ${data.eventRecord.data.woId}</dt>` : ''}
                    ${data.eventRecord.data.shiftId ? `<dt>${this.translate('shift')} : # ${data.eventRecord.data.shiftId}</dt>` : ''}
                    <dt>${this.translate('start_time')} : ${bryntum.scheduler.DateHelper.format(data.eventRecord.startDate, 'LLL')}</dt>
                    <dt>${this.translate('end_time')} : ${bryntum.scheduler.DateHelper.format(data.eventRecord.endDate, 'LLL')}</dt>
                </dl>`,
      },

      shiftTypes: [],
      priorityTypes: [],
      userList: [],
      roomList: [],
      deviceList: [],
      sopList: [],
      vendorList: [],
      workOrderNames: [],
      workOrderCategories: [],

      workOrderTypeList: [],
      workOrderStatusList: [],
      workList: [],
      dependencySeed: 1,
      contractList: [],
      workDependencies: [],
      workEventContextMenu: {
        items: [{
          cls: 'assign_User',
          text: this.$parent.translate('assign_staff'),
          icon: 'b-fa b-fa-user-plus',
        }],
      },
      workResources: [],
      workTimeRanges: [],
      workEvents: [],
      workColumns: [
        {
          type: 'rownumber',
          field: 'rownumber',
          groupable: false,
          draggable: false,
          hidden: true,
        },
        {
          field: 'workId',
          text: 'WorkId',
          width: 240,
          hidden: true,
        },
        {
          field: 'name',
          text: 'Name',
          width: 240,
        },
        {
          field: 'room',
          text: 'Room',
          width: 240,
          hidden: true,
        },
        {
          field: 'capacityEquipment',
          text: 'Capacity Equipment',
          width: 240,
        }],
      workGroup: { field: 'room' },
      userResources: [],
      userResourcesInventory: [],
      userTimeRanges: [],
      userEvents: [],
      userColumns: [
        {
          type: 'rownumber',
          field: 'rownumber',
          groupable: false,
          draggable: false,
          hidden: true,
        },
        {
          field: 'role',
          text: 'Role',
          width: 220,
          hidden: true,
        },
        {
          field: 'experience',
          text: 'Experience',
          hidden: true,
          width: 90,
        },
        {
          field: 'staff',
          text: 'Staff',
          width: 180,
        },
        {
          field: 'userType',
          text: 'User Type',
          width: 65,
          hidden: true,
        },
        {
          field: 'vendor',
          text: 'Vendor',
          width: 65,
          hidden: true,
        },
        {
          text: 'Master',
          width: 150,
          filterable: false,
          groupable: false,
          children: [{
            field: 'master',
            text: 'Master',
            width: 75,
            htmlEncode: false,
            filterable: false,
            draggable: false,
            renderer: ({ record, value }) => record.data.isAssignableToMaster
              ? `<div class="b-widget b-checkbox b-green">
                                    <input class="addRemove_workOrderUser" 
                                           type="checkbox" 
                                           ${value ? 'checked' : ''} 
                                           data-checked="${value}" 
                                           data-userid="${record.data.id}" 
                                           data-userposition="master" 
                                           data-userpositionid="${self.userPositionEnums.Master}"/>
                                    <label/>
                                </div>` : '',
                    },
                    {
                        field: 'isTrainedMaster',
                        text: 'Training',
                        width: 75,
                        htmlEncode: false,
                        filterable: false,
                        draggable: false,
                        renderer: ({ record, value }) =>
                        self.selectedWorkOrder.orderSourceId != self.workOrderSourceEnums.incident ?
                            `<div class="b-widget b-checkbox b-blue">
                                    <input type="checkbox" 
                                           disabled 
                                           ${value ? 'checked' : ''}/>
                                    <label/>
                                </div>` : '',
                    }]
                },
                {
                    text: 'Observer',
                    width: 150,
                    filterable: false,
                    groupable: false,
                    children: [{
                        field: 'observer',
                        text: 'Observer',
                        width: 75,
                        htmlEncode: false,
                        filterable: false,
                        draggable: false,
                        renderer: ({ record, value }) =>
                            record.data.isAssignableToObserver ?
                                `<div class="b-widget b-checkbox b-green">
                                <input class="addRemove_workOrderUser" 
                                       type="checkbox" 
                                       ${value ? 'checked' : ''} 
                                       data-checked="${value}" 
                                       data-userid="${record.data.id}" 
                                       data-userposition="observer" 
                                       data-userpositionid="${self.userPositionEnums.Observer}"/>
                                <label/>
                            </div>` : '',
                    },
                    {
                        field: 'isTrainedObserver',
                        text: 'Training',
                        width: 75,
                        htmlEncode: false,
                        filterable: false,
                        draggable: false,
                        renderer: ({ value }) =>
                            self.selectedWorkOrder.orderSourceId != self.workOrderSourceEnums.incident ?
                                `<div class="b-widget b-checkbox b-blue">
                                    <input type="checkbox" 
                                           disabled 
                                           ${value ? 'checked' : ''}/>
                                    <label/>
                                </div>`: '',
          }],
        }],
      userGroup: { field: 'role' },
      showAddWorkOrder2: false,
    };
  },
  props: ['workOrderId', 'maintenanceGroupId'],
  components: {
    scheduler,
  },
  async created() {
    const self = this;
    blockui.pageBlock(self);
    const promises = [
      self.getWorkOrderTypes(),
      self.getWorkOrderStatusList(),
      self.getWorkList(),
      self.getShiftTypes(),
      self.getWorkEvents(),
      self.getPriorityTypes(),
      self.getUserList(),
      self.getRoomList(),
      self.getDeviceList(),
      self.getAvailableSOPFormListForDeviceOrRoom(),
      self.getVendorList(),
      self.getWorkOrderNames(),
      self.getWorkOrderCategories(),
    ];
    await Promise.all(promises);
    blockui.unBlockPage();
    self.IsWorkReady = true;
    self.$nextTick(() => {
      self.arrangeTimeAxis();
    });
  },
  mounted() {
    $(document).off('click', '.addRemove_workOrderUser');
    $(document).on('click', '.addRemove_workOrderUser', this.addRemoveWorkOrderUser);
  },
  computed: {
    ...mapState({
      statusEnums: (state) => state.enums.workOrderStatus,
      userPositionEnums: (state) => state.enums.userPosition,
      workOrderSourceEnums: (state) => state.enums.workOrderSource,
    }),
    headerSelectedWorkOrder() {
      return this.selectedWorkOrder ? `#${this.selectedWorkOrder.id} - ${this.selectedWorkOrder.name}` : '';
    },
  },
  methods: {
    // #region Data Fetches
    async getWorkOrderTypes() {
      const self = this;
      await self.$globalApiClient.workOrderType.workOrderTypeGetWorkOrderTypes().then((res) => {
        self.workOrderTypeList = res.data;
      });
    },
    async getWorkOrderStatusList() {
      const self = this;
      await self.$globalApiClient.workOrderStatus.workOrderStatusGetList({workOrderSourceId:null}).then((res) => {
        self.workOrderStatusList = res.data.Data;
      });
    },
    async getWorkList() {
      const self = this;
      await self.$globalApiClient.work.workGetListWithBreadCrumb({deviceId:null}).then((res) => {
        self.workList = res.data;
      });
    },
    async getWorkOrderNames() {
      const self = this;
      await self.$globalApiClient.workOrder.workOrderSearchWorkOrderName({ name: null, orderSourceId: null }).then((res) => {
        self.workOrderNames = res.data;
      });
    },
    async getWorkOrderCategories() {
      const self = this;
      await self.$globalApiClient.common.commonGetWorkOrderCategories().then((res) => {
        self.workOrderCategories = res.data;
      });
    },
    async getShiftTypes() {
      const self = this;
      await self.$globalApiClient.shiftType.shiftTypeGetList().then((res) => {
        self.shiftTypes = res.data;
      });
    },
    async getPriorityTypes() {
      const self = this;
      await self.$globalApiClient.priorityType.priorityTypeGetAll().then((res) => {
        self.priorityTypes = res.data;
      });
    },
    async getUserList() {
      const self = this;
      await self.$globalApiClient.user.userGetUsers({ userTypeId: null }).then((res) => {
        self.userList = res.data;
      });
    },
    async getRoomList() {
      const self = this;
      await self.$globalApiClient.room.roomGetAllRooms().then((res) => {
        self.roomList = res.data;
      });
    },
    async getDeviceList() {
      const self = this;
      await self.$globalApiClient.capacityEquipment.capacityEquipmentGetCapacityEquipmentsBreadCrumb({ roomId: null, brandId: null, modelId: null }).then((res) => {
        self.deviceList = res.data;
      });
    },
    async getAvailableSOPFormListForDeviceOrRoom(deviceId, roomId) {
      const self = this;
      await self.$globalApiClient.form.formGetAvailableSopFormListForDeviceOrRoom({ deviceId: deviceId, roomId: roomId }).then((res) => {
        self.sopList = res.data;
      });
    },
    async getVendorList() {
      const self = this;
      await self.$globalApiClient.vendor.vendorGetVendors().then((res) => {
        self.vendorList = res.data;
      });
    },
    async getWorkEvents() {
      const self = this;
      self.workEvents = [];
      self.workResources = [];
      await self.$globalApiClient.workOrder.workOrderGetListById({
        workOrderId: self.workOrderId,
        maintenanceGroupId: self.maintenanceGroupId })
      .then((res) => {
        res.data.Data.map((workOrder) => {
          const isWorkDependent = workOrder.WorkId > 0;

          if (self.workResources.filter((x) => x.workId == workOrder.WorkId).length == 0) {
            const max = self.workResources.length > 0 ? Math.max(...self.workResources.map((x) => x.id)) : 0;
            self.workResources.push({
              id: max + 1,
              workId: isWorkDependent ? workOrder.WorkId : null,
              name: workOrder.Name,
              room: workOrder.RoomName,
              roomId: workOrder.RoomId,
              capacityEquipment: workOrder.DeviceName,
              capacityEquipmentId: workOrder.DeviceId,
            });
          }

          const editableStatus = (workOrder.OrderStatusId == self.statusEnums.planned || workOrder.OrderStatusId == self.statusEnums.assigned || workOrder.OrderStatusId == self.statusEnums.onHold || workOrder.OrderStatusId == self.statusEnums.new);

          const resourceId = isWorkDependent ? self.workResources.find((x) => x.workId == workOrder.WorkId).id : self.workResources.find((x) => !(x.workId > 0) && x.name == workOrder.Name).id;

          let userIds = [];
          if (workOrder.WorkOrderUsers) {
            userIds = workOrder.WorkOrderUsers.map((workOrderUser) => workOrderUser.UserId);
          }

          let formIds = [];
          if (workOrder.WorkOrderForms) {
            formIds = workOrder.WorkOrderForms.map((workOrderForm) => workOrderForm.FormId);
          }
          // workOrder.WorkOrderUsers = workOrder.UserIds.map(userId => ({ UserId: userId }));

          self.workEvents.push({
            id: workOrder.Id,
            resourceId,
            // name: workOrder.WorkName + (workOrder.MaintenanceGroupId ? ' (' + workOrder.MaintenanceGroupName + ')' : ''),
            startDate: new Date(workOrder.StartDate),
            endDate: new Date(workOrder.EndDate),
            durationUnit: 'minute',
            orderStatusId: workOrder.OrderStatusId,
            maintenanceGroupId: workOrder.MaintenanceGroupId,
            maintenanceGroupName: workOrder.MaintenanceGroupName,
            parentId: workOrder.ParentId,
            orderTypeId: workOrder.OrderTypeId,
            note: workOrder.Note,
            eventColor: workOrder.ColorCode.toLowerCase(),

            eventStyle: self.getEventStyle(workOrder.OrderSourceId),

            name: workOrder.Name,
            orderSourceId: workOrder.OrderSourceId,
            priorityTypeId: workOrder.PriorityTypeId,
            description: workOrder.Description,
            workId: workOrder.WorkId,

            roomId: workOrder.RoomId,
            deviceId: workOrder.DeviceId,
            userIds,
            formIds,
            workOrderCategoryId: workOrder.WorkOrderCategoryId,
            vendorNotificationRemainingDay: workOrder.VendorNotificationRemainingDay,
            vendorId: workOrder.VendorId,

            editable: editableStatus,
            resizable: editableStatus,
            draggable: editableStatus,
          });

          if (workOrder.ParentId) {
            self.workDependencies.push({
              id: self.dependencySeed++,
              from: workOrder.ParentId,
              to: workOrder.Id,
            });
          }
        });
        // self.arrangeTimeAxis();
        // blockui.unBlockPage();
      }, (error) => {
        // blockui.unBlockPage();
      });
    },
    // #endregion
    getEventStyle(orderSourceId) {
      let eventStyle = 'plain';
      if (orderSourceId == this.workOrderSourceEnums.incident) {
        eventStyle = 'colored';
      }
      return eventStyle;
    },
    onAddWorkOrder(e) {
      const self = this;
      const widgets = [];

      if (authWorkOrderSave()) {
        widgets.push({
          cls: 'b-raised',
          style: 'width: 100%; margin-top: 10px;',
          ref: 'plannedWorkOrderButton',
          type: 'button',
          text: self.translate('planned'),
          onClick: () => {
            self.createEvent(self.workOrderSourceEnums.planned);
          },
        });
      }
      if (authIncidentSave()) {
        widgets.push({
          cls: 'b-raised',
          style: 'width: 100%; margin-top: 10px;',
          ref: 'incidentButton',
          type: 'button',
          text: self.translate('incident'),
          onClick: () => {
            self.createEvent(self.workOrderSourceEnums.incident);
          },
        });
      }
      // {
      //     cls: 'b-raised',
      //     style: 'width: 100%; margin-top: 10px;',
      //     ref: 'unplannedWorkOrderButton',
      //     type: 'button',
      //     text: 'Unplanned',
      //     onClick: () => {
      //         self.createEvent(self.workOrderSourceEnums.case);
      //     }
      // }

      // createEvent
      const popup = new bryntum.scheduler.Popup({
        header: self.translate('add_task'),
        autoShow: false,
        centered: true,
        widgets,
      });
      popup.show();
    },
    getFilteredNames(name) {
      const self = this;
      const names = [];
      if (self.workOrderNames.indexOf(name) < 0) {
        names.push({ text: name });
      }
      if (name) {
        names.push(...self.workOrderNames.filter((workName) => workName.toLocaleLowerCase('tr').includes(name.toLocaleLowerCase('tr').trim())).map((x) => ({ text: x })));
      }
      return names;
    },
    getFilteredWork(name){
      const self = this;
      const names = [];
      let workNameList = self.workList.map((work) => ({
        text: work.BreadCrumb ? `${work.BreadCrumb} ${work.Name}` : work.Name,
        value: work.Id,
        duration: work.Duration,
      }));
      if (name) {
        names.push(...workNameList.filter((x) => x.text.toLocaleLowerCase('tr')
        .includes(name.toLocaleLowerCase('tr').trim()))
        .map((x) => ({ text: x.text , value: x.value, duration: x.duration})));
      }
      return names;
    },
    onOrderSourceChange(source, value) {
      const self = this;
      source.owner.items.forEach((widget) => {
        const isHidden = self.isWidgetHidden(widget, value);
        if (isHidden !== null) {
          widget.hidden = isHidden;
        }
      });
    },
    isWidgetHidden(widget, value) {
      if (widget.dataset && widget.dataset.orderSourceIds) {
        return !widget.dataset.orderSourceIds.includes(value);
      }
      return null;
    },
    createEvent(workOrderSourceId) {
      const self = this;
      // var orderSourceId = workOrderSourceId.toString();

      const currentTime = new Date().toString().split(':');
      const adjustableDate = self.workSchEngine.startDate.toString().split(':');
      const currentHourDate = adjustableDate.length > 3 ? 
        moment(`${currentTime[0].toString()}:${adjustableDate[1].toString()}:${adjustableDate[2].toString()}:${adjustableDate[3].toString()}`).toDate() : 
        moment(`${currentTime[0].toString()}:${adjustableDate[1].toString()}:${adjustableDate[2].toString()}`).toDate();
      //const currentHourDate = moment(`${currentTime[0].toString()}:${adjustableDate[1].toString()}:${adjustableDate[2].toString()}:${adjustableDate[3].toString()}`).toDate();
      const event = new bryntum.scheduler.EventModel({
        startDate: currentHourDate,
        orderSourceId: workOrderSourceId,
      });
      self.workSchEngine.editEvent(event);
    },
    addEvent(workOrder) {
      const self = this;
      const isWorkDependent = workOrder.WorkId > 0;
      let resource = isWorkDependent
        ? self.workResources.find((x) => x.workId == workOrder.WorkId)
        : self.workResources.find((x) => !(x.workId > 0)
                    && x.name == workOrder.Name
                    && x.capacityEquipmentId == workOrder.DeviceId
                    && x.roomId == workOrder.RoomId);

      if (!resource) {
        const max = self.workResources.length > 0 ? Math.max(...self.workResources.map((x) => x.id)) : 0;
        const newResource = {
          id: max + 1,
          name: workOrder.Name,
          workId: isWorkDependent ? workOrder.WorkId : null,
          room: workOrder.RoomName,
          roomId: workOrder.RoomId,
          capacityEquipment: workOrder.DeviceName,
          capacityEquipmentId: workOrder.DeviceId,
        };

        self.workResources.push(newResource);
        resource = newResource;
      }

      // remove all dependencies
      let children = [];
      const oldEvent = self.workSchEngine.eventStore.getById(workOrder.Id);
      if (oldEvent) {
        children = self.workSchEngine.features.dependencies.store.query((x) => x.from == workOrder.Id);
        self.workSchEngine.features.dependencies.store.query((x) => x.to == workOrder.Id || x.from == workOrder.Id).forEach((dependency) => {
          dependency.remove();
        });
        oldEvent.remove();
      }
      self.workSchEngine.eventStore.add({
        id: workOrder.Id,
        resourceId: resource.id,
        startDate: new Date(workOrder.ScheduledTime),
        duration: workOrder.Duration,
        durationUnit: 'minute',
        parentId: workOrder.ParentId,
        orderTypeId: workOrder.OrderTypeId,
        orderStatusId: workOrder.OrderStatusId,
        note: workOrder.Note,

        workId: workOrder.WorkId,

        eventColor: workOrder.Color,

        eventStyle: self.getEventStyle(workOrder.OrderSourceId),

        name: workOrder.Name,
        orderSourceId: workOrder.OrderSourceId,
        priorityTypeId: workOrder.PriorityTypeId,
        description: workOrder.Description,

        roomId: workOrder.RoomId,
        deviceId: workOrder.DeviceId,
        userIds: workOrder.UserIds,
        formIds: workOrder.FormIds,
        workOrderCategoryId: workOrder.WorkOrderCategoryId,
        vendorNotificationRemainingDay: workOrder.VendorNotificationRemainingDay,
        vendorId: workOrder.VendorId,
      });

      // self.workResources.filter(r=>self.workSchEngine.eventStore.query(e => e.resourceId == r.id).length == 0).forEach(emptyResource=>{
      //     const index = self.workResources.indexOf(emptyResource)
      //     if (index > -1) {
      //         self.workResources.splice(index, 1);
      //     }
      // });

      // update dependencies
      children.forEach((child) => {
        self.workSchEngine.features.dependencies.store.add(child);
      });
      if (workOrder.ParentId) {
        self.workSchEngine.features.dependencies.store.add({
          id: self.dependencySeed++,
          from: workOrder.ParentId,
          to: workOrder.Id ? workOrder.Id : parseInt(response.data.Messages.find((x) => x.Key == 'Id').Value),
        });
      }
    },
    initWorkScheduler() {
      document.querySelector('.b-grid-body-container.b-widget-scroller').style.overflowY = 'scroll';
      const self = this;
      self.workSchEngine = self.$refs.workScheduler.schedulerEngine;

      self.IsUserReady = true;

      // #region Arrangement buttons
      const schButtons = [{
        type: 'button',
        cls: 'b-raised b-blue',
        margin: 5,
        icon: 'b-fa b-fa-search-plus',
        tooltip: self.translate('zoom_in'),
        onAction: function onAction() {
          blockui.pageBlock(self);
          self.workSchEngine.zoomIn();
          blockui.unBlockPage();
        },
      }, {
        type: 'button',
        cls: 'b-raised b-blue',
        margin: 5,
        icon: 'b-fa b-fa-search',
        tooltip: self.translate('zoom_reset'),
        onAction: function onAction() {
          blockui.pageBlock(self);
          self.arrangeTimeAxis();
          blockui.unBlockPage();
        },
      }, {
        type: 'button',
        cls: 'b-raised b-blue',
        margin: 5,
        icon: 'b-fa b-fa-search-minus',
        tooltip: self.translate('zoom_out'),
        onAction: function onAction() {
          blockui.pageBlock(self);
          self.workSchEngine.zoomOut();
          blockui.unBlockPage();
        },
      }];
      self.widgetHelper.append({
        type: 'container',
        widgets: schButtons,
        cls: 'pull-left',
      }, {
        insertFirst: document.getElementsByClassName('schContainer')[0],
      });
      // #endregion

      // #region Work order status buttons
      const statusButtons = self.workOrderStatusList.map((status) => ({
        title: status.Id,
        type: 'button',
        tooltip: status.Name,
        icon: `${status.Icon} b-fa-fw`,
        cls: `b-raised b-${status.Color.Name.toLowerCase()}`,
        margin: 5,
        onAction: (e) => self.toggleWorkOrderStatusFilter(e, status),
      }));
      self.workContainer = self.widgetHelper.append({
        type: 'container',
        widgets: statusButtons,
        cls: 'pull-right',
      }, {
        insertFirst: document.getElementsByClassName('schContainer')[0],
      });
      // #endregion
      self.workSchEngine.eventEdit.editorConfig = {
        title: '',
        bbar: { widgets: [] },
        width: 400,
        triggerEvent: '',
        widgets: [
          // #region add incident work order
          {
            type: 'combo',
            name: 'orderSourceId',
            label: 'Type',
            items: [self.workOrderSourceEnums.planned.toString(), self.workOrderSourceEnums.case.toString(), self.workOrderSourceEnums.incident.toString()],
            hidden: true,
            required: true,
            listeners: {
              // TODO move this code to the event editor
              // #7809 - orderSourceId field should update visibility of the other EventEditor fields
              change: ({ source: combo, value }) => {
                // toggle visibility of widgets belonging to orderSources
                self.onOrderSourceChange(combo, value);
              },
            },
          },
          {
            type: 'combo',
            labelWidth: 400,
            label: self.translate('name'),
            emptyText: self.translate('type_name'),
            name: 'name',
            dataset: { orderSourceIds: [self.workOrderSourceEnums.incident] },
            validateFilter: false,
            required: true,
            // store:{data:[]},
            value: '',
            items: [''],
            onInput: (e) => {
              e.source.store.removeAll();
              const names = self.getFilteredNames(e.value);
              e.source.store.add(names);
              e.source.tempValue = e.value;
            },
            onAction: (e) => {
              if (e.value && e.value != e.source.tempValue) {
                if (self.workOrderNames.indexOf(e.source.tempValue) < 0) {
                  e.source.store.remove(e.source.tempValue);
                }
                e.source.tempValue = e.value;
              }
              e.source.value = e.source.tempValue;
            },
            onFocusout: (e) => {
              e.source.value = e.source.tempValue;
              e.source.tempValue = '';
            },
            onFocusin: (e) => {
              e.source.tempValue = e.source.value;
            },
          },
          {
            type: 'text',
            labelWidth: 400,
            label: self.translate('description'),
            name: 'description',
            clearable: true,
            dataset: { orderSourceIds: [self.workOrderSourceEnums.incident] },
          },
          {
            type: 'combo',
            label: self.translate('priority'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'priorityTypeId',
            required: true,
            filterOperator: '*',
            items: self.priorityTypes.length > 0 ? self.priorityTypes.map((priorityType) => ({ value: priorityType.Id, text: priorityType.Name })) : [{ text: '', value: 0 }],
            dataset: { orderSourceIds: [self.workOrderSourceEnums.incident] },
          },
          {
            type: 'combo',
            label: self.translate('assigned_staffs'),
            multiSelect: true,
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'userIds',
            value: '',
            filterOperator: '*',
            items: self.userList.length > 0 ? self.userList.map((user) => ({ value: user.Value, text: user.Text })) : [{ text: '', value: 0 }],
            dataset: { orderSourceIds: [self.workOrderSourceEnums.incident] },
          },
          {
            type: 'combo',
            label: self.translate('room'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'roomId',
            value: '',
            filterOperator: '*',
            items: self.roomList.length > 0 ? self.roomList.map((room) => ({ value: room.Value, text: room.Text })) : [{ text: '', value: 0 }],
            dataset: { orderSourceIds: [self.workOrderSourceEnums.incident] },
            clearable: true,
            onAction: async function onAction(e) {
              const deviceCombo = self.workSchEngine.eventEdit.editor.widgets.find((x) => x.name == 'deviceId');
              const formCombo = self.workSchEngine.eventEdit.editor.widgets.find((x) => x.name == 'formIds');
              e.source.owner.mask(bryntum.scheduler.LocaleManager.locale.Grid.loadMask);
              const capacityEquipments = (await self.$globalApiClient.capacityEquipment.capacityEquipmentGetCapacityEquipmentsBreadCrumb({ roomId: e.value })).data.map((device) => ({ value: device.Value, text: device.Text }));
              deviceCombo.store.removeAll();
              deviceCombo.store.add(capacityEquipments);

              const forms = (await self.$globalApiClient.form.formGetAvailableSopFormListForDeviceOrRoom({ deviceId: deviceCombo.value, roomId: e.value })).data.map((form) => ({ value: form.Value, text: form.Text }));
              let extraForms = [];

              if (formCombo.value && formCombo.value.length > 0) {
                const selectedNotExistFormIds = formCombo.value.filter((selectedValue) => forms.map((x) => x.value).indexOf(selectedValue) < 0);
                extraForms = self.sopList.filter((x) => selectedNotExistFormIds.indexOf(x.Value) >= 0).map((form) => ({ value: form.Value, text: form.Text }));
              }

              formCombo.store.removeAll();
              formCombo.store.add(forms.concat(extraForms));
              e.source.owner.unmask();
            },
          },
          {
            type: 'combo',
            label: self.translate('capacity_equipment'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'deviceId',
            value: '',
            filterOperator: '*',
            items: self.deviceList.length > 0 ? self.deviceList.map((device) => ({ value: device.Value, text: device.Text })) : [{ text: '', value: 0 }],
            dataset: { orderSourceIds: [self.workOrderSourceEnums.incident] },
            clearable: true,
            onAction: async function onAction(e) {
              const roomCombo = self.workSchEngine.eventEdit.editor.widgets.find((x) => x.name == 'roomId');
              if (e.record) {
                // roomCombo.value = '';
                roomCombo.hide();
              } else {
                roomCombo.show();
              }

              const formCombo = self.workSchEngine.eventEdit.editor.widgets.find((x) => x.name == 'formIds');
              e.source.owner.mask(bryntum.scheduler.LocaleManager.locale.Grid.loadMask);
              const forms = (await self.$globalApiClient.form.formGetAvailableSopFormListForDeviceOrRoom({ deviceId: e.value, roomId: null })).data.map((form) => ({ value: form.Value, text: form.Text }));

              let extraForms = [];
              if (formCombo.value && formCombo.value.length > 0) {
                const selectedNotExistFormIds = formCombo.value.filter((selectedValue) => forms.map((x) => x.value).indexOf(selectedValue) < 0);
                extraForms = self.sopList.filter((x) => selectedNotExistFormIds.indexOf(x.Value) >= 0).map((form) => ({ value: form.Value, text: form.Text }));
              }

              formCombo.store.removeAll();
              formCombo.store.add(forms.concat(extraForms));
              e.source.owner.unmask();
            },
          },
          {
            type: 'combo',
            label: self.translate('form'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'formIds',
            value: '',
            filterOperator: '*',
            multiSelect: true,
            items: self.sopList.length > 0 ? self.sopList.map((sop) => ({ value: sop.Value, text: sop.Text })) : [{ text: '', value: 0 }],
            dataset: { orderSourceIds: [self.workOrderSourceEnums.incident] },
            clearable: true,
          },
          {
            type: 'combo',
            label: self.translate('work_order_category'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'workOrderCategoryId',
            value: '',
            filterOperator: '*',
            items: self.workOrderCategories.length > 0 ? self.workOrderCategories.map((workOrderCategory) => ({ value: workOrderCategory.Value, text: workOrderCategory.Text })) : [{ text: '', value: 0 }],
            dataset: { orderSourceIds: [self.workOrderSourceEnums.incident] },
            clearable: true,
          },
          {
            type: 'combo',
            label: self.translate('work'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'workId',
            required: true,
            value: '',
            // filterOperator: '*',
            items: [{ duration: 0, text: '', value: 0 }],
            dataset: { orderSourceIds: [self.workOrderSourceEnums.planned, self.workOrderSourceEnums.case] },
            validateFilter: false,
            onInput: (e) => {
              e.source.store.removeAll();
              const names = self.getFilteredWork(e.value);
              e.source.store.add(names);
              e.source.tempValue = e.value;
            },
            onAction: async function onAction(e) {
              if (e.record) self.workSchEngine.eventEdit.editor.widgets.find((x) => x.name == 'durationMinute').value = e.record.duration;
              if (e.value && e.value != e.source.tempValue) {
                if (self.workOrderNames.indexOf(e.source.tempValue) < 0) {
                  e.source.store.remove(e.source.tempValue);
                }
                e.source.tempValue = e.value;
              }
              e.source.value = e.source.tempValue;

              const contractCombo = self.workSchEngine.eventEdit.editor.widgets.find((x) => x.name == 'contractId');
              contractCombo.store.removeAll();
              if(e.value !== null && e.value !== undefined){
                this.contractList = (await self.$globalApiClient.contract.contractGetListByWorkId({ workId: e.value }))
                  .data.Data.map((contract) => ({ 
                    value: contract.Id, 
                    text: `${contract.Name} (${dateFormat.viewDateFormat(contract.StartDate)} - ${dateFormat.viewDateFormat(contract.EndDate)})`, 
                    startDate: contract.StartDate, 
                    endDate: contract.EndDate 
                  }));
                contractCombo.store.add(this.contractList);
              }
            },
            onFocusout: (e) => {
              e.source.value = e.source.tempValue;
              e.source.tempValue = '';
              
            },
            onFocusin: (e) => {
              e.source.tempValue = e.source.value;
            },
          },
          {
            type: 'combo',
            label: self.translate('main_work'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'parentId',
            filterOperator: '*',
            items: [{ text: '', value: 0 }],
            dataset: { orderSourceIds: [self.workOrderSourceEnums.planned, self.workOrderSourceEnums.case] },
            onAction: function onAction(e) {
            },
          },
          {
            type: 'number',
            label: `${self.translate('duration')} (${self.translate('minute')})`,
            labelWidth: 400,
            required: true,
            name: 'durationMinute',
            min: 1,
            value: 0,
          },
          {
            type: 'combo',
            label: self.translate('maintenance_type'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'orderTypeId',
            required: true,
            filterOperator: '*',
            items: self.workOrderTypeList.length > 0 ? self.workOrderTypeList.map((workOrderType) => [workOrderType.Value, workOrderType.Text]) : [{ text: '', value: 0 }],
            dataset: { orderSourceIds: [self.workOrderSourceEnums.planned, self.workOrderSourceEnums.case] },
          },
          {
            type: 'date',
            label: self.translate('start_date'),
            labelWidth: 400,
            name: 'startDate',
            editable: false,
          },
          {
            type: 'time',
            label: self.translate('start_time'),
            cls: 'startTime',
            labelWidth: 400,
            name: 'startDate',
            editable: false,
          },
          {
            type: 'combo',
            label: self.translate('contract_list'),
            emptyText: self.translate('no_option'),
            name: 'contractId',
            items: [{ text: '', value: 0, startDate: new Date(), endDate: new Date() }],
            dataset: { orderSourceIds: [self.workOrderSourceEnums.planned, self.workOrderSourceEnums.case] },
          },
          {
            type: 'checkbox',
            text: self.translate('send_work_order_notification_to_vendor'),
            name: 'sendNotificationCheckbox',
            cls: 'send-notification-checkbox',
            dataset: { orderSourceIds: [self.workOrderSourceEnums.planned, self.workOrderSourceEnums.case] },
            onAction: function onAction(e) {
              if (!e.checked) {
                self.workSchEngine.eventEdit.editor.widgets.find((x) => x.name == 'vendorId').hide();
                self.workSchEngine.eventEdit.editor.widgets.find((x) => x.name == 'vendorNotificationRemainingDay').hide();
              } else {
                self.workSchEngine.eventEdit.editor.widgets.find((x) => x.name == 'vendorId').show();
                self.workSchEngine.eventEdit.editor.widgets.find((x) => x.name == 'vendorNotificationRemainingDay').show();
              }
            },
          },
          {
            type: 'combo',
            label: self.translate('vendor'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'vendorId',
            required: true,
            filterOperator: '*',
            items: self.vendorList.length > 0 ? self.vendorList.map((vendor) => [vendor.Value, vendor.Text]) : [{ text: '', value: 0 }],
            dataset: { orderSourceIds: [self.workOrderSourceEnums.planned, self.workOrderSourceEnums.case] },
          },
          {
            type: 'number',
            label: self.translate('notify_before_days'),
            labelWidth: 400,
            required: true,
            name: 'vendorNotificationRemainingDay',
            min: 1,
            value: 0,
          },
          {
            type: 'text',
            labelWidth: 400,
            label: self.translate('note'),
            name: 'note',
            clearable: true,
          },
          {
            type: 'button',
            cls: 'b-green b-raised',
            text: self.translate('save'),
            name: 'save',
            onAction: self.onWorkSave,
          },
          {
            type: 'button',
            cls: 'b-red b-raised',
            text: self.translate('delete'),
            name: 'delete',
            onAction: async function onAction() {
              blockui.pageBlock(self);
              const { id } = self.workSchEngine.eventEdit.values;
              self.deleteWorkEvent(id);
            },
          },
          {
            type: 'button',
            cls: 'b-gray b-raised',
            text: self.translate('cancel'),
            name: 'cancel',
            onAction: function onAction() {
              self.workSchEngine.eventEdit.editor.hide();
            },
          },
          {
            type: 'text',
            cls: 'b-hidden',
            editable: 'false',
            text: 'Id',
            name: 'id',
          },
          {
            type: 'text',
            cls: 'b-hidden',
            editable: 'false',
            text: 'OrderStatusId',
            name: 'orderStatusId',
          }],
      };

      self.workSchEngine.timeAxisColumn.headerMenuItems = [{
        cls: 'add_Work',
        text: self.translate('add_new_work_to_work_order'),
        icon: 'b-fa b-fa-plus',
      }];

      // self.workSchEngine.on('beforeEventEditShow', () => {
      //     let widgets = self.workSchEngine.eventEdit.editor.widgets;
      // });

      self.workSchEngine.on('beforeEventEdit', self.beforeWorkEventEdit);
      self.workSchEngine.on('beforeeventdropfinalize', self.beforeWorkEventDropFinalize);
      self.workSchEngine.on('beforeeventresizefinalize', self.beforeWorkEventResizeFinalize);
      self.workSchEngine.on({
        contextMenuItem({ record, item }) {
          if (item.cls === 'add_Work') {
            self.createEvent();
          }
        },
        async eventcontextmenuitem({ source, item, eventRecord }) {
          if (item.cls === 'assign_User') {
            blockui.pageBlock(self);
            self.showAddWorkOrder2 = true;
            self.selectedWorkOrder = eventRecord.data;
            self.selectedWorkOrderEvent = eventRecord;
            self.getSuitableWorkUsers(eventRecord);
            self.userSchEngine.height = '50vh';
            self.workSchEngine.height = '20vh';
            // let resource = self.workSchEngine.resourceStore.getById(eventRecord.resourceId);
            // self.workSchEngine.scrollRowIntoView(resource, {block:'center',  highlight: true});
            self.workSchEngine.scrollEventIntoView(eventRecord, { block: 'center', highlight: true });
          } else if (item.icon == 'b-icon b-icon-trash') {
            blockui.pageBlock(self);
            const dependencies = self.workSchEngine.features.dependencies.store.query((x) => x.from === eventRecord.id || x.to === eventRecord.id);
            // todo dependencies already removed ?
            const success = await self.deleteWorkEvent(eventRecord.id);
            if (!success) {
              self.$nextTick(() => {
                self.workSchEngine.eventStore.add(eventRecord);
                // todo add removed dependencies ?
              });
            }
          }
        },
      });
      self.workSchEngine.on('scheduleDblClick', async (context) => {
        // Check is double click is in resource
        if (!Number.isInteger(context.resourceRecord.id)) { self.workSchEngine.eventEdit.editor.hide(); }
      });
    },
    async onWorkSave(e) {
      const self = this;
      const { values } = self.workSchEngine.eventEdit;
      
      e.source.owner.mask(bryntum.scheduler.LocaleManager.locale.Grid.loadMask);
      let { name } = values;
      let dateNow = new Date();
      if (values.orderSourceId != self.workOrderSourceEnums.incident) {
        const work = self.workList.find((x) => x.Id == values.workId);
        if (!work) {
          e.source.owner.unmask();
          return;
        }
        name = work.Name;
        const contract = (await self.$globalApiClient.contract.contractGetListByWorkId({ workId: values.workId }))
          .data.Data.find((contract)=>(contract.Id == values.contractId));
        if(contract !== null && contract !== undefined){
          if(dateNow > new Date(contract.EndDate)){
            const event = await swal.qaqccheck(this, this.translate('contract_control_warning_message') );
            if(!event.value){
              e.source.owner.unmask();
              return;
            }
          }
        }
      }

      const workOrder = {
        Id: values.id,
        ParentId: values.parentId ? values.parentId : null,
        Duration: values.durationMinute,
        WorkId: values.workId,
        OrderTypeId: values.orderTypeId,
        OrderStatusId: values.orderStatusId,
        ScheduledTime: dateFormat.postDateTimeFormat(values.startDate),
        Note: values.note,

        // change
        Name: name,
        OrderSourceId: values.orderSourceId,
        PriorityTypeId: values.priorityTypeId,
        Description: values.description,

        RoomId: values.roomId,
        DeviceId: values.deviceId,
        UserIds: values.userIds,
        FormIds: values.formIds,
        WorkOrderCategoryId: values.workOrderCategoryId,

        ContractId: values.contractId,
      };

      const sendNotificationCheckbox = self.workSchEngine.eventEdit.editor.widgets.find((x) => x.name == 'sendNotificationCheckbox');
      if (sendNotificationCheckbox.checked) {
        workOrder.VendorNotificationRemainingDay = values.vendorNotificationRemainingDay;
        workOrder.VendorId = values.vendorId;
      }

      const invalidWidget = self.workSchEngine.eventEdit.editor.widgets.find((x) => x.isVisible && x.required && !x.value);
      if (invalidWidget) {
        invalidWidget.setError(self.translate('this_field_is_required'));
      } else {
        let success = false;
        success = await self.saveWorkOrder(workOrder, true);
        if (success) self.arrangeTimeAxis();
      }
      e.source.owner.unmask();
    },
    beforeWorkEventEdit({ eventRecord, resourceRecord, eventElement }) {
      const self = this;
      const isEdit = Number.isInteger(eventRecord.id);

      const { widgets } = self.workSchEngine.eventEdit.editor;
      const mainWorks = self.workSchEngine.eventStore.query((x) => !x.parentId && x.data.orderSourceId != self.workOrderSourceEnums.incident && x.id != eventRecord.id).map((event) => [event.id, `#${event.id} ${event.name}`]);
      mainWorks.unshift([0, self.translate('none')]);
      let workItems = self.workList.map((work) => ({
        text: work.BreadCrumb ? `${work.BreadCrumb} ${work.Name}` : work.Name,
        value: work.Id,
        duration: work.Duration,
      }));

      const orderSourceId = eventRecord.data.orderSourceId ? eventRecord.data.orderSourceId : (resourceRecord && resourceRecord.workId > 0 ? self.workOrderSourceEnums.planned : self.workOrderSourceEnums.incident);
      if (isEdit || resourceRecord) {
        if ((orderSourceId == self.workOrderSourceEnums.planned && !authWorkOrderSave()) || (orderSourceId == self.workOrderSourceEnums.incident && !authIncidentSave())) {
          toastr.error(self.translate('you_dont_have_permission'));
          self.$nextTick(() => {
            self.workSchEngine.eventEdit.editor.hide();
          });
        }

        workItems = workItems.filter((x) => x.value == ((eventRecord.data && eventRecord.data.workId) || (resourceRecord && resourceRecord.workId)));
      }

      self.$nextTick(() => {
        self.workSchEngine.eventEdit.editor.autoClose = false; // fix for editor being closed when modified outside
        widgets.find((x) => x.name == 'parentId').items = mainWorks;
        widgets.find((x) => x.name == 'id').value = isEdit ? eventRecord.id : 0;
        widgets.find((x) => x.name == 'parentId').value = eventRecord.data.parentId ? eventRecord.data.parentId : self.translate('none');
        widgets.find((x) => x.name == 'workId').items = workItems;
        widgets.find((x) => x.name == 'workId').value = isEdit ? eventRecord.data.workId : null;
        widgets.find((x) => x.name == 'contractId').value = isEdit ? eventRecord.data.contractId : null;

        widgets.find((x) => x.name == 'durationMinute').value = self.dateHelper.diff(eventRecord.startDate, eventRecord.endDate, 'minute');
        widgets.find((x) => x.name == 'orderTypeId').value = eventRecord.data.orderTypeId ? eventRecord.data.orderTypeId : null;
        widgets.find((x) => x.name == 'note').value = eventRecord.data.note ? eventRecord.data.note : null;
        widgets.find((x) => x.name == 'orderStatusId').value = eventRecord.data.orderStatusId ? eventRecord.data.orderStatusId : self.workOrderStatusList[0].Id;

        widgets.find((x) => x.name == 'orderSourceId').value = orderSourceId;

        widgets.find((x) => x.name == 'priorityTypeId').value = eventRecord.data.priorityTypeId ? eventRecord.data.priorityTypeId : null;
        widgets.find((x) => x.name == 'description').value = eventRecord.data.description ? eventRecord.data.description : null;
        widgets.find((x) => x.name == 'workOrderCategoryId').value = eventRecord.data.workOrderCategoryId ? eventRecord.data.workOrderCategoryId : null;

        widgets.find((x) => x.name == 'vendorNotificationRemainingDay').value = eventRecord.data.vendorNotificationRemainingDay ? eventRecord.data.vendorNotificationRemainingDay : null;
        widgets.find((x) => x.name == 'vendorId').value = eventRecord.data.vendorId ? eventRecord.data.vendorId : null;
        const sendNotificationCheckbox = widgets.find((x) => x.name == 'sendNotificationCheckbox');
        if (eventRecord.data.vendorNotificationRemainingDay > 0) {
          sendNotificationCheckbox.check();
          widgets.find((x) => x.name == 'vendorId').show();
          widgets.find((x) => x.name == 'vendorNotificationRemainingDay').show();
        } else {
          sendNotificationCheckbox.uncheck();
          widgets.find((x) => x.name == 'vendorId').hide();
          widgets.find((x) => x.name == 'vendorNotificationRemainingDay').hide();
        }

        const userIds = eventRecord.data.userIds ? eventRecord.data.userIds.map((x) => x.id) : [];
        const userIdsWidget = widgets.find((x) => x.name == 'userIds');
        userIdsWidget.value = [];
        userIdsWidget.value = userIds;

        const formIds = eventRecord.data.formIds ? eventRecord.data.formIds.map((x) => x.id) : [];
        const formIdsWidget = widgets.find((x) => x.name == 'formIds');
        formIdsWidget.value = [];
        formIdsWidget.value = formIds;

        widgets.find((x) => x.name == 'roomId').value = eventRecord.data.roomId ? eventRecord.data.roomId : null;
        widgets.find((x) => x.name == 'deviceId').value = eventRecord.data.deviceId ? eventRecord.data.deviceId : null;

        const namewidget = widgets.find((x) => x.name == 'name');
        namewidget.store.removeAll();
        if (eventRecord.data.name) {
          const names = self.getFilteredNames(eventRecord.data.name);
          namewidget.store.add(names);
        }
        namewidget.tempValue = eventRecord.data.name;
        namewidget.value = eventRecord.data.name;

        if (isEdit) {
          // widgets.find(x => x.name == 'workId').hide();
          widgets.find((x) => x.name == 'delete').show();
        } else {
          // widgets.find(x => x.name == 'workId').show();
          widgets.find((x) => x.name == 'delete').hide();
        }

        if (eventRecord.data.orderSourceId != self.workOrderSourceEnums.incident) {
          if (!eventRecord.data.parentId && isEdit && self.workSchEngine.features.dependencies.store.query((x) => x.from == eventRecord.id).length) {
            widgets.find((x) => x.name == 'parentId').hide();
          } else {
            widgets.find((x) => x.name == 'parentId').show();
          }
        } else {
          widgets.find((x) => x.name == 'parentId').hide();
        }
        self.workSchEngine.eventEdit.editor.autoClose = false;
      });
    },
    async beforeWorkEventDropFinalize(params) {
      const self = this;
      const { context } = params;
      // blockui.pageBlock(self);
      context.async = true;
      context.draggedRecords.forEach(async (record) => {
        const workOrder = {
          Id: record.id,
          ParentId: record.data.parentId ? record.data.parentId : null,
          Duration: self.dateHelper.diff(context.startDate, context.endDate, 'm'),
          WorkId: record.workId ? record.workId : null,
          OrderTypeId: record.data.orderTypeId ? record.data.orderTypeId : null,
          OrderStatusId: record.data.orderStatusId,
          ScheduledTime: dateFormat.postDateTimeFormat(context.startDate),
          Note: record.data.note ? record.data.note : null,

          Name: record.data.name,
          OrderSourceId: record.data.orderSourceId,
          PriorityTypeId: record.data.priorityTypeId ? record.data.priorityTypeId : null,
          Description: record.data.description ? record.data.description : null,

          RoomId: record.roomId ? record.roomId : null,
          DeviceId: record.deviceId ? record.deviceId : null,
          UserIds: record.userIds ? record.userIds : null,
          FormIds: record.formIds ? record.formIds : null,
          WorkOrderCategoryId: record.workOrderCategoryId ? record.workOrderCategoryId : null,
          VendorNotificationRemainingDay: record.vendorNotificationRemainingDay ? record.vendorNotificationRemainingDay : null,
          VendorId: record.vendorId ? record.vendorId : null,
        };
        const success = await self.saveWorkOrder(workOrder, false);

        if (success && self.selectedWorkOrderEvent) {
          blockui.pageBlock(self);
          const { selectedWorkOrderEvent } = self;
          selectedWorkOrderEvent.data = {
            ...self.selectedWorkOrderEvent.data,
            startDate: context.startDate,
            endDate: context.endDate,
          };
          self.selectedWorkOrderEvent = selectedWorkOrderEvent;
          self.selectedWorkOrder = selectedWorkOrderEvent.data;
          self.getSuitableWorkUsers(self.selectedWorkOrderEvent);
          // self.userSchEngine.height = '50vh';
          // self.workSchEngine.height = '20vh';
          self.workSchEngine.scrollEventIntoView(self.selectedWorkOrderEvent, {
            block: 'center',
            highlight: true,
          });
        }

        context.finalize(success);
        if (success) self.arrangeTimeAxis({ dontAdjustHeight: !!self.selectedWorkOrderEvent });
      });
    },
    async beforeWorkEventResizeFinalize({ context }) {
      const self = this;
      context.async = true;
      const workOrder = {
        Id: context.eventRecord.id,
        ParentId: context.eventRecord.data.parentId ? context.eventRecord.data.parentId : null,
        Duration: self.dateHelper.diff(context.startDate, context.endDate, 'm'),
        WorkId: context.eventRecord.data.workId ? context.eventRecord.data.workId : null,
        OrderTypeId: context.eventRecord.data.orderTypeId ? context.eventRecord.data.orderTypeId : null,
        OrderStatusId: context.eventRecord.data.orderStatusId,
        ScheduledTime: dateFormat.postDateTimeFormat(context.startDate),
        Note: context.eventRecord.data.note ? context.eventRecord.data.note : null,

        Name: context.eventRecord.data.name,
        OrderSourceId: context.eventRecord.data.orderSourceId,
        PriorityTypeId: context.eventRecord.data.priorityTypeId ? context.eventRecord.data.priorityTypeId : null,
        Description: context.eventRecord.data.description ? context.eventRecord.data.description : null,

        RoomId: context.eventRecord.roomId ? context.eventRecord.roomId : null,
        DeviceId: context.eventRecord.deviceId ? context.eventRecord.deviceId : null,
        UserIds: context.eventRecord.userIds ? context.eventRecord.userIds : null,
        FormIds: context.eventRecord.formIds ? context.eventRecord.formIds : null,
        WorkOrderCategoryId: context.eventRecord.workOrderCategoryId ? context.eventRecord.workOrderCategoryId : null,
        VendorNotificationRemainingDay: context.eventRecord.vendorNotificationRemainingDay ? context.eventRecord.vendorNotificationRemainingDay : null,
        VendorId: context.eventRecord.vendorId ? context.eventRecord.vendorId : null,
      };
      const success = await self.saveWorkOrder(workOrder, false);

      if (self.selectedWorkOrderEvent) {
        blockui.pageBlock(self);
        const { selectedWorkOrderEvent } = self;
        selectedWorkOrderEvent.data = {
          ...self.selectedWorkOrderEvent.data,
          startDate: context.startDate,
          endDate: context.endDate,
        };
        self.selectedWorkOrderEvent = selectedWorkOrderEvent;
        self.selectedWorkOrder = selectedWorkOrderEvent.data;
        self.getSuitableWorkUsers(self.selectedWorkOrderEvent);
        // self.userSchEngine.height = '50vh';
        // self.workSchEngine.height = '20vh';
        self.workSchEngine.scrollEventIntoView(self.selectedWorkOrderEvent, {
          block: 'center',
          highlight: true,
        });
      }

      context.finalize(success);
      if (success) self.arrangeTimeAxis({ dontAdjustHeight: !!self.selectedWorkOrderEvent });
      self.workSchEngine.refreshWithTransition();
    },
    initUserScheduler() {
      const self = this;
      self.userSchEngine = self.$refs.userScheduler.schedulerEngine;

      const shiftButtons = self.shiftTypes.map((shift) => ({
        type: 'button',
        text: shift.Name,
        icon: 'b-fa b-fa-calendar-alt b-fa-fw',
        tooltip: `${shift.StartTime} - ${shift.EndTime}`,
        cls: `b-raised b-${shift.Color.Name.toLowerCase()}`,
        margin: 5,
        onAction: (e) => self.toggleUserShiftFilter(e, shift),
      }));
      self.shiftContainer = self.widgetHelper.append({
        type: 'container',
        widgets: shiftButtons,
        cls: 'pull-right',
      }, {
        insertFirst: document.getElementsByClassName('schContainer')[1],
      });

      self.userSchEngine.on('beforeeventdropfinalize', ({ context }) => {
        context.draggedRecords.forEach((record) => {
          let done = false;
          const year = context.startDate.getFullYear();
          const month = context.startDate.getMonth();
          const day = context.startDate.getDate();
          self.shiftTypes.forEach((shift) => {
            const sTimes = shift.StartTime.split(':');
            const eTimes = shift.EndTime.split(':');
            const startDate = new Date(year, month, day, sTimes[0], sTimes[1], sTimes[2]);
            const endDate = new Date(year, month, day, eTimes[0], eTimes[1], eTimes[2]);
            if (shift.StartTime > shift.EndTime) {
              startDate.setDate(startDate.getDate() - 1);
            }
            if (!done && context.startDate >= startDate && context.startDate <= endDate) {
              done = true;
              if (startDate.getTime() < new Date().getTime()) {
                toastr.error(self.translate('shift_calendar_error'));
                context.finalize(false);
              } else if (self.userSchEngine.eventStore.query((r) => r.startDate.getTime() == startDate.getTime() && r.endDate.getTime() == endDate.getTime() && r.data.resourceId == record.data.resourceId && r.data.shiftTypeId == shift.Id).length > 0) {
                toastr.error(self.translate('shift_exist_error_message'));
                context.finalize(false);
              } else {
                context.async = true;
                record.data.name = shift.Name;
                context.startDate = startDate;
                context.endDate = endDate;
                record.data.startDate = startDate;
                record.data.endDate = endDate;
                record.data.shift = shift.Name;
                record.data.shiftTypeId = shift.Id;
                record.data.eventColor = shift.Color.Name.toLowerCase();
                blockui.pageBlock(self);
                self.saveShift(record.data).then((id) => {
                  context.finalize(id > 0);
                  blockui.unBlockPage();
                });
              }
            }
          });
        });
      });

      self.userSchEngine.on('scheduleClick', (context) => {
        if (self.userSchEngine.readOnly || !Number.isInteger(context.resourceRecord.data.id)) return;
        let done = false;
        const year = context.date.getFullYear();
        const month = context.date.getMonth();
        const day = context.date.getDate();
        self.shiftTypes.forEach((shift) => {
          const sTimes = shift.StartTime.split(':');
          const eTimes = shift.EndTime.split(':');
          const startDate = new Date(year, month, day, sTimes[0], sTimes[1], sTimes[2]);
          const endDate = new Date(year, month, day, eTimes[0], eTimes[1], eTimes[2]);
          if (shift.StartTime > shift.EndTime) {
            startDate.setDate(startDate.getDate() - 1);
          }
          if (!done && context.date >= startDate && context.date <= endDate) {
            done = true;
            if (startDate.getTime() < new Date().getTime()) {
              toastr.error(self.translate('shift_calendar_error'));
            } else if (self.userSchEngine.eventStore.query((r) => r.startDate.getTime() == startDate.getTime() && r.endDate.getTime() == endDate.getTime() && r.data.resourceId == context.resourceRecord.data.id && r.data.shiftTypeId == shift.Id).length > 0) {
              toastr.error(self.translate('shift_exist_error_message'));
            } else {
              const event = {
                id: 0,
                resourceId: context.resourceRecord.data.id,
                name: shift.Name,
                startDate,
                endDate,
                eventStyle: 'line',
                eventColor: shift.Color.Name.toLowerCase(),
                shiftTypeId: shift.Id,
              };
              blockui.pageBlock(self);
              self.saveShift(event).then((id) => {
                event.id = id;
                if (event.id > 0) self.userSchEngine.eventStore.add(event);
                blockui.unBlockPage();
              });
            }
          }
        });
      });

      self.userSchEngine.on('eventClick', ({ eventRecord, resourceRecord }) => {
        if (self.userSchEngine.readOnly || eventRecord && !Number.isInteger(eventRecord.id)) return;
        const swalTitle = `${resourceRecord.staff} 
        ${self.translate('shift')} ${eventRecord.name}
        ${dateFormat.viewDateFormat(eventRecord.startDate)}`;
        swal.check(self, swalTitle, self.translate('will_be_deleted_permanently')).then((e) => {
          if (e.value) self.deleteShift(eventRecord.id);
        });
      });
    },
    arrangeTimeAxis(params) {
      const self = this;
      self.userResources = [];
      self.userEvents = [];
      if (!params || !params.dontAdjustHeight) {
        self.userSchEngine.height = '20vh';
        self.workSchEngine.height = '50vh';
      }
      self.$nextTick(() => {
        let eventExist = false;
        let max = new Date('1970-01-01');
        let min = new Date('2100-01-01');
        self.workSchEngine.eventStore.forEach((event) => {
          eventExist = true;
          if (self.dateHelper.compare(event.startDate, min) < 0) min = event.startDate;
          if (self.dateHelper.compare(event.endDate, max) > 0) max = event.endDate;
        });
        if (!eventExist) {
          min = new Date();
          max = min;
        }
        min = self.dateHelper.add(min, -3, 'hour');
        max = self.dateHelper.add(max, 3, 'hour');
        self.workSchEngine.setTimeSpan(min, max);
        self.workTimeRanges = [];
        const formData = {
          beginDate: dateFormat.postDateTimeFormat(min),
          endDate: dateFormat.postDateTimeFormat(max),
        };
        self.$globalApiClient.scheduleWork.scheduleWorkValidate(formData).then((res) => {
          if (res.data.IsSuccess) {
            res.data.Data.map((item) => {
              self.workTimeRanges.push({
                name: item.Name,
                cls: `shift timerange-${item.IsSuitable ? 'gray' : 'orange'}`,
                startDate: new Date(item.ScheduledBeginDate),
                endDate: new Date(item.ScheduledEndDate),
              });
            });
          } else {
            toastr.error(res.data.Message);
          }
        }, (error) => {
        });

        self.$globalApiClient.scheduleDevice.scheduleDeviceValidate(formData).then((res) => {
          if (res.data.IsSuccess) {
            res.data.Data.map((item) => {
              self.workTimeRanges.push({
                name: item.Name,
                cls: `shift timerange-${item.IsSuitable ? 'cyan' : 'violet'}`,
                startDate: new Date(item.ScheduledBeginDate),
                endDate: new Date(item.ScheduledEndDate),
              });
            });
          } else {
            toastr.error(res.data.Message);
          }
        }, (error) => {
        });
        self.$globalApiClient.scheduleRoom.scheduleRoomValidate(formData).then((res) => {
          if (res.data.IsSuccess) {
            res.data.Data.map((item) => {
              self.workTimeRanges.push({
                name: item.Name,
                cls: `shift timerange-${item.IsSuitable ? 'lime' : 'red'}`,
                startDate: new Date(item.ScheduledBeginDate),
                endDate: new Date(item.ScheduledEndDate),
              });
            });
          } else {
            toastr.error(res.data.Message);
          }
        }, (error) => {
        });
      });
    },
    async deleteWorkEvent(id) {
      const self = this;
      let success = false;
      await self.$globalApiClient.workOrder.workOrderDelete({ id: id }).then((response) => {
        if (response.data.IsSuccess) {
          success = true;
          if (self.workSchEngine.eventEdit.editor) self.workSchEngine.eventEdit.editor.hide();
          toastr.success(self.translate('delete_successfully'));

          // TODO fix removing all depend events
          self.workSchEngine.eventStore.query((x) => x.id == id || x.parentId == id).forEach((event) => {
            self.workSchEngine.features.dependencies.store.query((x) => x.from == event.id || x.to == event.id).forEach((dependency) => {
              dependency.remove();
            });
            event.remove();
          });

          self.arrangeTimeAxis();
        } else {
          toastr.error(response.data.Message);
        }
      }, (error) => {
      });
      blockui.unBlockPage();
      return success;
    },
    getTimeRanges() {
      const self = this;
      self.userTimeRanges = [];
      let loop = self.dateHelper.add(self.workSchEngine.startDate, -1, 'd');
      const end = self.dateHelper.add(self.workSchEngine.endDate, 3, 'd');
      while (loop <= end) {
        self.shiftTypes.forEach((shift) => {
          const month = loop.getMonth();
          const day = loop.getDate();
          const year = loop.getFullYear();
          const sTimes = shift.StartTime.split(':');
          const eTimes = shift.EndTime.split(':');
          let dayDiff = 0;
          if (shift.StartTime > shift.EndTime) dayDiff++;
          self.userTimeRanges.push({
            name: shift.Name,
            cls: `shift timerange-${shift.Color.Name.toLowerCase()}`,
            startDate: new Date(year, month, day, sTimes[0], sTimes[1], sTimes[2]),
            endDate: new Date(year, month, day + dayDiff, eTimes[0], eTimes[1], eTimes[2]),
          });
        });
        loop = self.dateHelper.add(loop, 1, 'd');
      }
    },
    saveShift(event) {
      const self = this;
      let id = 0;
      const shift = {
        UserId: event.resourceId,
        Id: event.id,
        ShiftTypeId: event.shiftTypeId,
        ShiftDate: dateFormat.postDateTimeFormat(event.startDate),
      };
      return new Promise((resolve) => {
        self.$globalApiClient.shift.shiftSave(shift).then((response) => {
          if (response.data.IsSuccess) {
            toastr.success(self.translate(`${shift.Id > 0 ? 'update' : 'save'}_successfully`));
            id = response.data.Data.Id;
          } else {
            toastr.error(response.data.Message);
          }
          resolve(id);
        }, (error) => {
          resolve(id);
        });
      });
    },
    deleteShift(id) {
      const self = this;
      const shiftDate = self.userSchEngine.eventStore.getById(id).startDate;
      if (shiftDate.getTime() < new Date().getTime()) {
        toastr.error(self.translate('unable_to_delete_shift'));
        return;
      }
      blockui.pageBlock(self);
      self.$globalApiClient.shift.shiftDelete(Number.isInteger(id) ? id : 0).then((response) => {
        if (response.data.IsSuccess) {
          toastr.success(self.translate('delete_successfully'));
          self.userSchEngine.eventStore.getById(id).remove();
        } else {
          toastr.error(response.data.Message);
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },
    getSuitableWorkUsers(event) {
      const self = this;
      self.userSchEngine.eventStore.clearFilters();
      self.getTimeRanges();
      self.$globalApiClient.workOrderUser.workOrderUserGetSuitableWorkUsers({
        workOrderId: event.data.id,
      }).then((res) => {
        self.userResources = [];
        self.userResourcesInventory = [];
        self.userSchEngine.eventStore.removeAll();
        const userList = res.data;
        userList.forEach((user) => {
          const tempResource = {
            id: user.Id,
            staff: user.Name,
            role: user.Role,
            vendor: user.Vendor,
            userType: user.UserType,
            experience: user.UserExperience,
            isAssignableToMaster: user.IsAssignableToMaster,
            isAssignableToObserver: user.IsAssignableToObserver,
            master: user.IsMaster,
            observer: user.IsObserver,
            isTrainedMaster: user.IsTrainedForMaster,
            isTrainedObserver: user.IsTrainedForObserver,
          };

          if (user.Shifts) {
            user.Shifts.forEach((shift) => {
              self.userSchEngine.eventStore.add({
                id: shift.Id,
                resourceId: user.Id,
                name: shift.ShiftType,
                startDate: new Date(shift.StartDate),
                endDate: new Date(shift.EndDate),
                shift: shift.ShiftType,
                eventColor: shift.ColorName.toLowerCase(),
                eventStyle: 'line',
                shiftTypeId: shift.ShiftTypeId,
                shiftId: shift.Id,
              });
            });
          }

          if (user.WorkOrders) {
            user.WorkOrders.forEach((workOrder) => {
              self.userSchEngine.eventStore.add({
                id: `Wo:${workOrder.WorkOrderId}_User:${user.Id}`,
                woId: workOrder.WorkOrderId,
                resourceId: user.Id,
                name: event.data.id === workOrder.WorkOrderId ? event.data.name : workOrder.BreadCrumb ? `${workOrder.BreadCrumb} (${workOrder.WorkName})` : workOrder.WorkName,
                startDate: new Date(workOrder.StartDate),
                endDate: new Date(workOrder.EndDate),
                eventColor: workOrder.ColorName.toLowerCase(),
                eventStyle: event.data.id === workOrder.WorkOrderId ? 'plain' : 'hollow',
                editable: false,
                resizable: false,
                draggable: false,
              });
              if (event.data.id === workOrder.WorkOrderId) tempResource[workOrder.UserPosition.toLowerCase()] = true; // remove when Userpositions-> IsActive return true
            });
          }

          self.userResources.push(tempResource);
          self.userResourcesInventory.push({
            ...tempResource,
            WorkOrders: user.WorkOrders,
            Shifts: user.Shifts,
          });
        });

        self.shiftContainer[0].items.forEach((btn) => {
          if (btn.icon !== 'b-fa b-fa-calendar-alt') {
            self.userSchEngine.eventStore.filter((x) => x.name === btn.text || x.eventStyle === 'hollow');
          }
        });
        // TODO: Temporary solution. Find where the filters come from.
        self.userSchEngine.eventStore.clearFilters();

        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },
    async saveWorkOrder(workOrder, updateDom, forceSave) {
      const self = this;
      blockui.pageBlock(self);
      let response = null;

      workOrder.AssignedUserIds = workOrder.UserIds;
      workOrder.RoomId = workOrder.DeviceId > 0 ? null : workOrder.RoomId;

      try {
        if (workOrder.OrderSourceId == self.workOrderSourceEnums.planned) {
          response = await self.$globalApiClient.workOrder.workOrderSave(workOrder, {forceSave: forceSave});
        }
        if (workOrder.OrderSourceId == self.workOrderSourceEnums.case) {
          response = await self.$globalApiClient.workOrder.workOrderSave(workOrder, {forceSave: forceSave});
        } else if (workOrder.OrderSourceId == self.workOrderSourceEnums.incident) {
          response = await self.$globalApiClient.incident.incidentSave(workOrder, {forceSave: forceSave});
        }
      } catch (e) {
        blockui.unBlockPage();
        return false;
      }
      self.workSchEngine.eventEdit.editor.hide();

      if (!response.data.IsSuccess) {
        const answer = await swal.checkWithHtmlContent(self, self.translate('work_order_time_conflict_title'), response.data.Message);
        if (answer.value) {
          blockui.unBlockPage();
          return await this.saveWorkOrder(workOrder, updateDom, true);
        }
        blockui.unBlockPage();
        return false;
      }

      toastr.success(self.translate(workOrder.Id == 0 ? 'save_successfully' : 'update_successfully'));
      if (!updateDom) {
        blockui.unBlockPage();
        return true;
      }

      workOrder.Color = response.data.Messages.find((x) => x.Key == 'OrderStatus.Color').Value.toLowerCase();
      const orderStatusId = response.data.Messages.find((x) => x.Key == 'OrderStatusId');
      workOrder.OrderStatusId = orderStatusId ? orderStatusId.Value : null;
      const roomName = response.data.Messages.find((x) => x.Key == 'RoomName');
      workOrder.RoomName = roomName ? roomName.Value : null;
      const roomId = response.data.Messages.find((x) => x.Key == 'RoomId');
      workOrder.RoomId = roomId ? roomId.Value : null;
      const deviceName = response.data.Messages.find((x) => x.Key == 'DeviceName');
      workOrder.DeviceName = deviceName ? deviceName.Value : null;
      const deviceId = response.data.Messages.find((x) => x.Key == 'DeviceId');
      workOrder.DeviceId = deviceId ? deviceId.Value : null;

      workOrder.Id = workOrder.Id ? workOrder.Id : parseInt(response.data.Messages.find((x) => x.Key == 'Id').Value);
      self.addEvent(workOrder);
      self.arrangeTimeAxis();
      blockui.unBlockPage();
      return true;
    },
    async saveWorkOrderUser(workOrderUser, forceSave) {
      const self = this;
      let response = null;
      try {
        response = await self.$globalApiClient.workOrderUser.workOrderUserSave(workOrderUser, {forceSave: forceSave});
      } catch (e) {
        return false;
      }
      if (response.data.IsSuccess) {
        return response.data;
      }

      const answer = await swal.checkWithHtmlContent(self, self.translate('work_order_time_conflict_title'), response.data.Message);
      if (answer.value) {
        return await this.saveWorkOrderUser(workOrderUser, true);
      }
      return false;
    },
    async addRemoveWorkOrderUser(event) {
      const self = this;
      let success = false;
      const checkbox = event.target;
      blockui.pageBlock(self);
      self.userSchEngine.storeScroll();
      self.workSchEngine.eventStore.filter;
      if ($(checkbox).is(':checked')) {
        const userObj = self.userResourcesInventory.filter((i) => i.id === $(checkbox).data('userid'))[0];
        const workOrderUser = {
          WorkOrderId: self.selectedWorkOrder.id,
          UserId: userObj.id,
          UserPositionId: $(checkbox).data('userpositionid'),
        };

        success = await this.saveWorkOrderUser(workOrderUser);

        if (success) {
          let selectedStatus = self.workOrderStatusList.find((x) => x.Id == self.selectedWorkOrder.orderStatusId);
          let assignedColor = selectedStatus.Color.Name.toLowerCase();
          let assignedColorCode = selectedStatus.Color.Code.toLowerCase();

          const orderStatus = success.Messages.find((x) => x.Key == 'OrderStatusId');
          const orderStatusId = orderStatus ? orderStatus.Value : null;

          if (orderStatusId != self.selectedWorkOrderEvent.orderStatusId) {
            selectedStatus = self.workOrderStatusList.find((x) => x.Id == orderStatusId);
            assignedColor = selectedStatus.Color.Name.toLowerCase();
            assignedColorCode = selectedStatus.Color.Code.toLowerCase();
            const workOrderEvent = self.workSchEngine.eventStore.getById(workOrderUser.WorkOrderId);
            const workOrderUserEvents = self.userSchEngine.eventStore.query((x) => x.woId == workOrderUser.WorkOrderId);
            for (const workOrderUserEvent of workOrderUserEvents) {
              workOrderUserEvent.set({
                //eventColor: assignedColor,
                eventColor: assignedColorCode
              });
            }
            workOrderEvent.set({
              orderStatusId,
              //eventColor: assignedColor,
              eventColor: assignedColorCode
            });
          }
          // #region Check the checkbox
          self.userSchEngine.resourceStore.getById($(checkbox).data('userid')).set($(checkbox).data('userposition'), true);
          // #endregion Check the checkbox

          // #region Add work order to user scheduler
          const newOrder = {
            id: `Wo:${workOrderUser.WorkOrderId}_User:${workOrderUser.UserId}`,
            woId: workOrderUser.WorkOrderId,
            resourceId: workOrderUser.UserId,
            name: self.selectedWorkOrder.name,
            startDate: new Date(self.selectedWorkOrder.startDate),
            endDate: new Date(self.selectedWorkOrder.endDate),
            eventColor: assignedColor, // todo server should return status
            eventStyle: 'plain',
            editable: false,
            resizable: false,
            draggable: false,
          };
          self.userSchEngine.eventStore.add(newOrder);
          self.userResourcesInventory = self.userResourcesInventory.map((i) => {
            if (i.id === $(checkbox).data('userid')) {
              i.WorkOrders.push({
                ...newOrder,
                WorkOrderId: workOrderUser.WorkOrderId,
              });
            }
            return i;
          });
          // #endregion Add work order to user scheduler

          toastr.success(self.translate('save_successfully'));
        }
      } else {
        const formData = {
          workOrderId: self.selectedWorkOrder.id,
          userId: $(checkbox).data('userid'),
        };
        await self.$globalApiClient.workOrderUser.workOrderUserDeleteWorkOrderUsers(formData).then((response) => {
          if (response.data.IsSuccess) {
            success = true;
            self.userSchEngine.resourceStore.getById($(checkbox).data('userid')).set($(checkbox).data('userposition'), false);
            toastr.success(self.translate('delete_successfully'));
            if (self.userSchEngine.eventStore.query((x) => x.id === `Wo:${formData.workOrderId}_User:${formData.userId}`)[0]) {
              self.userSchEngine.eventStore.query((x) => x.id === `Wo:${formData.workOrderId}_User:${formData.userId}`)[0].remove();
            }
            self.userResourcesInventory = self.userResourcesInventory.map((i) => {
              if (i.id === $(checkbox).data('userid')) {
                i.WorkOrders = i.WorkOrders.filter((i) => i.WorkOrderId !== self.selectedWorkOrder.id);
              }
              return i;
            });
          } else toastr.error(response.data.Message);
        }, (error) => {
        });
      }

      if (!success) {
        self.userSchEngine.renderContents();
      } else {
        const workOrderEvent = self.workSchEngine.eventStore.find((x) => x.id == self.selectedWorkOrder.id);
        const response = await self.$globalApiClient.workOrderUser.workOrderUserGetList({workOrderId: self.selectedWorkOrder.id});
        workOrderEvent.userIds = response.data.Data.map((x) => x.UserId);
        self.userSchEngine.scrollRowIntoView($(checkbox).data('userid'), { highlight: true, animate: false });
      }
      blockui.unBlockPage();
    },
    // #region Filter toggle
    toggleWorkOrderStatusFilter({ event, source }, status) {
      const self = this;
      self.workSchEngine.eventStore.clearFilters();
      if (!source.currentElement.classList.contains('filtered')) {
        self.workContainer[0].items.forEach((btn) => {
          btn.currentElement.classList.remove('filtered');
          btn.icon = `${self.workOrderStatusList.find((x) => x.Id === btn.title).Icon} b-fa-fw`;
        });
        source.currentElement.classList.add('filtered');
        source.icon = 'b-fa b-fa-filter  b-fa-fw';

        self.workSchEngine.eventStore.filter((x) => x.orderStatusId === status.Id);
      } else {
        source.currentElement.classList.remove('filtered');
        source.icon = `${self.workOrderStatusList.find((x) => x.Id === source.title).Icon} b-fa-fw`;
      }
    },
    toggleUserShiftFilter({ event, source }, shift) {
      const self = this;
      self.userSchEngine.eventStore.clearFilters();
      if (source.icon === 'b-fa b-fa-calendar-alt b-fa-fw') {
        self.shiftContainer[0].items.forEach((btn) => {
          btn.icon = 'b-fa b-fa-calendar-alt b-fa-fw';
        });
        source.icon = 'b-fa b-fa-filter b-fa-fw';
        self.userSchEngine.eventStore.filter((x) => x.name === shift.Name || x.data.woId);
      } else {
        source.icon = 'b-fa b-fa-calendar-alt b-fa-fw';
      }
    },
    // #endregion
  },
  beforeDestroy() {
    const self = this;
    self.userSchEngine.store.clearFilters();
  },
};